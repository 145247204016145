



import { Component, Vue, Emit } from 'vue-property-decorator';
import VueRouter, {Route} from 'vue-router';
import axios, {AxiosResponse, AxiosError} from 'axios';
import StepNavi from '../../../../../components/contents/stepNavi/StepNavi.vue';
import { load } from 'recaptcha-v3';
import auth from '../../../../../store/auth';
import store from '@/store';
import { CourseResponseCourses } from 'types/typescript-axios/api';
import { isReservedStatus, isCancelNoteStatus } from '../../../../../plugins/helper';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
]);

async function getDatas(to: Route) {
  let status: number = 200;
  let url: string = '/wp-json/moc/course/';

  const $to: any = to;

  const paramsName: any = $to.params.courceId;
  url = `${url}${paramsName}/detail`;

  const contentsData = await axios.get<AxiosResponse>(url);
  status = contentsData.status;

  return {
    status,
    contentsData,
    paramsName,
    $to,
  };
}

@Component({
  components: {
    StepNavi,
  },
})
export default class AccountCourseDetailCancelConfirm extends Vue {
  private paramsName: any = '';
  private status: number = 200;
  private get courseDetail(): CourseResponseCourses {
    const $parent: any = this.$parent;
    return $parent.courseDetail;
  }
  private get stepNavi(): string {
    const $parent: any = this.$parent;
    return $parent.stepNavi;
  }
  private get title(): any {
    const $parent: any = this.$parent;
    return $parent.title;
  }
  private get statusText(): string {
    const status1 = isReservedStatus(this.courseDetail);
    switch (status1) {
      case 0:
        return '申込み中';
      case 1:
        return 'キャンセル';
      case 2:
        return 'キャンセル待ち';
      default:
        return '未申し込み';
    }
  }

  private get isNoteStatus(): number {
    return isCancelNoteStatus(this.courseDetail);
  }

  public async onCancel() {
    const $param: any = {};
    const recaptcha = await load(process.env.VUE_APP_RECAPTCHA_KEY);
    const token = await recaptcha.execute('homepage');
    $param.recaptchaToken = token;
    $param.token = auth.state.token;
    const nonce = await store.dispatch('nonce/getData', null , {root: true});
    const query = new URLSearchParams(nonce);
    const url: string = `/wp-json/moc/course/${this.courseDetail.data2.acf?.postgres_id}/cancel?${query}`;
    axios.put<AxiosResponse>(url, $param)
    .then((response: any) => {
      this.$router.push(`/account/course/detail/${this.$route.params?.courceId}/cancel/complete/`);
    }, (error: any) => {
      store.commit('window/setNotFound', 403);
      this.$router.push(`/account/course/detail/${this.$route.params?.courceId}/cancel/error/`);
    });
  }

  private async beforeRouteEnter(to: Route, from: Route, next: any) {
    store.commit(
      'window/setTitle',
      `申込みキャンセル ${store.getters['window/isTitle']} 申込み講座のキャンセル ` + process.env.VUE_APP_TITLE_SUFFIX,
    );
    next();
  }

  @Emit()
  private dateFilter(date: string) {
    const d: Date = new Date(date);
    const dayArr = ['日', '月', '火', '水', '木', '金', '土'];
    const day = dayArr[d.getDay()];
    return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`;
  }
  @Emit()
  private brReplace(item: string) {
    let result = '';
    result = item.replace( /\n|\r\n|\r/g, '<br>' );
    return result;
  }
}
